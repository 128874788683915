
.bodysignin {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #e6e7e8;
}

.bodyadmin {
    background-image: url('../images/concrete-background.jpg');
    background-size: 100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.forgot-password {
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.logo {
    text-align: center;
}

.btn-primary :hover{
    background-color: #034ea1;
    border-color: #044d9f;
}

#sidebarMenu {
    background-color: #f1face76;
    color: black;
}

#sidebarMenu .nav-item a{
    color: black;
}

.footer-admin {
    // text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    color: rgb(7, 16, 66);
    background-color: #fdfffd;
    // padding: px;
}


// Customização do Guest

.logo_guest {
    // margin-left: 75px;
    height: 70px;
}

#menu_inclinado {
	//width: 175px;
	height: 45px;
	-webkit-transform: skew(-20deg);
	   -moz-transform: skew(-20deg);
	     -o-transform: skew(-20deg);
	background:#034ea1;
    border-color: #034ea1;
    cursor: pointer;
    margin-left: 10px;
    padding: 10px;
    color: white;
    border-radius: 5px;
}

.navbar-collapse {
    flex-grow: 0;
}

.container-fluid.my-container-fuid {
    justify-content: center;
}

.navbar-nav {
    text-align: center;
}

.bignav {
    background: #e6e7e8;
}

.bignav_inclinado {
	width: 300px;
	height: 80px;
	-webkit-transform: skew(-20deg);
	   -moz-transform: skew(-20deg);
	     -o-transform: skew(-20deg);
	background:#034ea1;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-top: 15px;
    color: white;
    border-radius: 5px;
    text-align: center;
}

.bignav_inclinado:hover {
    background: #022083;
    color: white;
}

// Btn whatsapp

.whatsapp-button-wrapper {
    position: fixed !important;
    bottom: 80px !important;
    right: -10px !important
}

.whatsapp-icon-button {
    position: relative !important;
    right: 0px !important;
    width: 100px !important;
    height: 100px !important;
    transition: .5s !important;
}
.whatsapp-icon {
    width: 60px !important;
    height: 60px !important;
    position: absolute !important;
    bottom: 10px !important;
    left: 10px !important;
}

.whatsapp-icon-button:hover {
    filter: brightness(115%) !important;
    transform: rotate(25deg) scale(1.25) !important;
    cursor: pointer !important
}

// Carrossel dos cards
// Cards Carousel
// ----------------------------------------------

.icon-cards {
    position: relative;
    width: 100vw;
    height: 100vw;
    max-width: 600px;
    max-height: 250px;
    margin: 0;
    // color: white;
        perspective: 1000px;
    transform-origin: center;

    // This is the element that rotates with the animation

    &__content {
        position: absolute;
        width: 600px;
        height: 250px;
    transform-origin: center;
        transform-style: preserve-3d;
        transform: translateZ(-30vw) rotateY(0);
        animation: carousel 10s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;

    &.step-animation {
    animation: carousel 1s infinite steps(1) forwards;
    }
    }

    // Individual cards

    &__item {
        position: absolute;
        top: 0;
        left: 0;
    right: 0;
    bottom: 0;
        // width: 60vw;
        // height: 40vw;
    max-width: 600px;
    max-height: 250px;
    // box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
        // border-radius: 6px;
    // transform-origin: center;

        &:nth-child(1) {
            // background: #e6e7e8;
            // border-radius: 15px;
            transform: rotateY(0) translateZ(35vw);
        }

        &:nth-child(2) {
            // background: #e6e7e8;
            // border-radius: 15px;
            transform: rotateY(120deg) translateZ(35vw);
        }

        &:nth-child(3) {
            // background: #e6e7e8;
            // border-radius: 15px;
            transform: rotateY(240deg) translateZ(35vw);
        }
    }
}

// Carousel animation

@keyframes carousel {
    0%,  17.5%  { transform: translateZ(-35vw) rotateY(0); }
    27.5%, 45%  { transform: translateZ(-35vw) rotateY(-120deg); }
    55%, 72.5%  { transform: translateZ(-35vw) rotateY(-240deg); }
    82.5%, 100% { transform: translateZ(-35vw) rotateY(-360deg); }
}

.carrossel-card {
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

// Jelly checkbox animation

@keyframes jelly {
from { transform: scale(1, 1); }
30% { transform: scale(1.25, 0.75); }
40% { transform: scale(0.75, 1.25); }
50% { transform: scale(1.15, 0.85); }
65% { transform: scale(0.95, 1.05); }
75% { transform: scale(1.05, 0.95); }
to { transform: scale(1, 1); }
}


// Barra Metricas
.back-metricas{
    background:#034ea1;
    height: 100px;
}

.card-body p {
    font-size: 20px;
    line-height: 1;
}

.metricas {
    background:#034ea1;
    color: white;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid white;
    margin: 20px;
}

.metrica-inclinado {
    height: 80px !important;
	-webkit-transform: skew(-20deg);
	   -moz-transform: skew(-20deg);
	     -o-transform: skew(-20deg);
	background:#034ea1;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: white;
    border-radius: 10px;
    border: 2px solid #ffffff;
    text-align: center;
}

.metrica-inclinado .card-body{
    padding: 6px;
}

.metrica-inclinado p{
    font-size: small;
}

.cards-wrapper {
    display: flex;
    justify-content: center;
}

.card img {
    width: 200px;
    padding: 5px;
    // max-width: 100%;
    // max-height: 100%;
}
span.carousel-control-next-icon {
    background-color: #034ea1;
    border-radius: 10px;
}
span.carousel-control-prev-icon {
    background-color: #034ea1;
    border-radius: 10px;
}

.compra-facilitada {
    background: #e6e7e8;
}
.compra-direita {

    height: 100px;
    line-height: 100px;
    text-align: center;
}

.compra-centro {
    padding: 25px;
}
.compra-esquerda {
    padding: 25px;
}

.cotacao_inclinado {
    height: 45px;
	-webkit-transform: skew(-20deg);
	   -moz-transform: skew(-20deg);
	     -o-transform: skew(-20deg);
	background:#034ea1;
    border-color: #034ea1;
    cursor: pointer;
    margin-left: 10px;
    padding: 10px;
    color: white;
    border-radius: 5px;
}

.cotacao_inclinado:hover {
    background: #022083;
    color: white;
}
@media screen and (max-width: 991px) {
    .back-metricas{
        background:#034ea1;
        height: 100%;
    }

}

@media screen and (max-width: 745px) {
    // Carrossel dos cards
    // Cards Carousel
    // ----------------------------------------------

    .icon-cards {
        position: relative;
        width: 100vw;
        height: 100vw;
        max-width: 300px;
        max-height: 125px;
        margin: 0;
        perspective: 500px;
        transform-origin: center;

        // This is the element that rotates with the animation

        &__content {
            position: absolute;
            width: 300px;
            height: 125px;
        }
    }
}

